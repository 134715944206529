import { DEFAULT_APPROVAL_FILTER, OMP, NL } from "./constants";

const config = {
  clientCode: OMP,
  clientId: 10027,
  lang: [NL],
  defaultLang: NL,
  uiSettings: {
    brandSelection: true,
    languageSwitch: false,
    toolsMenu: false,
    downloadsMenu: false,
    showPrices: true,
    showCustomerApproval: true,
    approvalFilter: DEFAULT_APPROVAL_FILTER,
    showAvailableCycles: false,
    showMigrationLink: false,
    sidebarFAQ: null,
    showFooterText: false,
    maintenanceMessage: null,
    showCalendar: true,
    showSuppliers: true,
    showRequestAccountButton: true,
    showSidebarAL: true,
    showLogos: true,
    showSignatures: true,
    weekendParticipationDate: true,
    participationDateDelay: 21,
    showParticipationDate: false,
    publicUrl: "https://portal.openmarketingplatform.nl/",
  },
  downloadsPageReport: {
    reportId: null,
    viewId: null,
  },
};

export default config;
